//Colors
$backgroundColor: #0c0c0c;
$backgroundHighlight: #474747;
$primaryFontColor: #ffffff;
$secondaryFontColor: #a7a7a7;
$primaryBrandColor: #ffbb00;
$solidBlack: #000000;
$cardColor: #1e1e1e;

//media Queries
$mobileWidth: 480px;
$tabletWidth: 1224px;
$laptopWidth: 1440px;

//Font
$primaryFont: "Roboto Mono", monospace;
