.about-me {
  @include standard-padding;
  display: grid;
  grid-template-columns: 6fr 4fr;
  text-align: left;
  padding-top: 100px;
  margin-top: 100px;
  margin-bottom: 100px;
  box-sizing: border-box;

  .left-container {
  }

  .about-me-text {
    color: $secondaryFontColor;
    font-size: 1rem;
    margin-bottom: 50px;
  }

  .sub-header {
    font-size: 1.5rem;
    font-weight: regular;
  }

  .technology-list {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: column;
    padding: 0;
  }

  .technology-item {
    font-size: 1.5rem;
    font-style: italic;
    display: flex;
    align-items: center;
    color: $secondaryFontColor;
    padding: 0.75rem 0;
    .bullet-point {
      color: $primaryBrandColor;
      font-size: 2rem;
    }
  }

  .right-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-container {
    border-radius: 10px;
    position: relative;
    .overlay-black {
      background-color: rgba(0, 0, 0, 0.24);
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 10px;
    }
    .overlay-yellow {
      background-color: $primaryBrandColor;
      opacity: 0.08;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 10px;
    }
    .stylized-border {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 15px;
      left: 20px;
      border: solid $primaryBrandColor 4px;
      border-radius: 10px;
      opacity: 0.7;
    }
  }

  .self-portrait {
    width: 350px;
    border-radius: 10px;
    position: relative;
    display: block;
    z-index: 0;
  }
}

@media screen and (max-width: $mobileWidth) {
  .about-me {
    margin-top: 0;
    margin-bottom: 40px;
    padding-top: 120px;
    display: flex;
    flex-direction: column;

    .sub-header {
      font-size: 1.25rem;
    }
    .technology-list {
      display: flex;
      flex-direction: column;
    }

    .self-portrait {
      width: 250px;
    }

    .image-container {
      margin-top: 50px;
    }
  }
}

@media screen and (max-width: $laptopWidth) {
  .about-me {
    .technology-item {
      font-size: 1.25rem;
    }
  }
}
