.work {
  @include standard-padding;
  padding: 0 250px;
  text-align: left;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-top: 200px;
  .work-container {
    display: grid;
    width: 100%;
    padding: 0;
    grid-template-columns: 3fr 7fr;
    ul {
      padding: 0;
      text-align: left;
      font-size: 1.25rem;
      font-weight: regular;
    }
    .work-selector-item {
      padding: 1.5rem 1rem;
      box-sizing: border-box;
      margin-right: 20%;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .selected {
    background-color: rgba(71, 71, 71, 0.3);
    border-radius: 10px;
  }

  .selected-information {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    min-height: 400px;
    .job-title {
      margin-bottom: 10px;
    }
    .company {
      color: $primaryBrandColor;
    }
    .dates-worked {
      color: $secondaryFontColor;
    }
  }

  .experience-item {
    font-size: 1rem;
    font-weight: regular;
    color: $secondaryFontColor;
    display: flex;
    align-items: center;
    margin: 20px 0;
    .bullet-point {
      color: $primaryBrandColor;
      font-size: 2rem;
    }
  }
}

@media screen and (max-width: $laptopWidth) {
  .work {
    padding: 100px;
    .work-selector-item {
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .work {
    padding: 20px;
    padding-top: 120px;
    margin-top: 0;
    .work-container {
      display: flex;
      flex-direction: column;

      .work-selector-item {
        font-size: 0.75rem;
        padding: 10px 15px;
        white-space: nowrap;
        margin: 0;

        text-align: center;
      }

      ul {
        display: flex;
        flex-direction: row;
        overflow: scroll;

        &.work-selector-list {
          border-bottom: 2px solid $backgroundHighlight;
        }

        &.experience-list {
          display: flex;
          flex-direction: column;
        }
      }

      .selected-information {
        padding: 0px;
      }
    }

    .work-selector {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .selected {
      border-radius: 4px;
    }
  }
}
