@mixin standard-padding {
  padding: 0 100px;
  width: 100vw;
  max-width: 100%;
  box-sizing: border-box;
  //mobile or small screen
  @media screen and (max-width: $mobileWidth) {
    padding: 20px;
  }
}
