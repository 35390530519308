.projects {
  @include standard-padding;
  min-height: 500px;
  padding-top: 100px;
  margin-bottom: 100px;
  .header-container {
    padding-right: 40%;
  }
  .project-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
  }
}
@media screen and (max-width: $laptopWidth) {
  .projects {
    .header-container {
      padding: 0;
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .projects {
    padding-top: 120px;
    margin-bottom: 0;
    .header-container {
      padding: 0;
    }
    .project-list {
      margin-top: 40px;
      margin-bottom: 0;
      justify-content: center;
      align-items: center;
    }
  }
}
