.contact-me {
  @include standard-padding;
  padding-top: 200px;
  padding-right: 350px;
  padding-left: 350px;
  box-sizing: border-box;
  min-height: 900px;

  .contact-me-message {
    box-sizing: border-box;
    color: $secondaryFontColor;
    padding: 50px 100px;
    font-size: 1.5rem;
  }

  .information-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .information-list {
    padding: 0;
    text-align: left;
  }

  .information-item {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .icon-link {
      margin-right: 20px;
      font-size: 1.5rem;
      color: $primaryBrandColor;
    }
    span {
      color: $secondaryFontColor;
    }
  }
}

//laptop styles
@media screen and (max-width: $laptopWidth) {
  .contact-me {
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 100px;
    .contact-me-message {
      padding: 50px 50px;
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .contact-me {
    padding-top: 120px;
    padding-left: 0;
    padding-right: 0;
    min-height: 90vh;
    .contact-me-message {
      padding: 0;
      padding-top: 20px;
      padding-bottom: 60px;
      font-size: 1rem;
    }
  }
}
