.App {
  text-align: center;
  footer {
    display: flex;
    flex-direction: column;
    color: $secondaryFontColor;
    font-size: 0.75rem;
    a {
      color: $secondaryFontColor;
    }
    margin-bottom: 50px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
