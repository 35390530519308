.hero {
  @include standard-padding;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: calc(100vh - 100px);
  .name-and-title {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .name {
    font-size: 6rem;
    font-weight: bold;
    opacity: 0;
    animation: slide-up 0.75s ease-in-out 1s forwards;
  }
  .title {
    color: $secondaryFontColor;
    font-size: 3.5rem;
    opacity: 0;
    animation: slide-up 0.9s ease-in-out 1s forwards;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 100px;
  }

  .hero-info {
    color: $secondaryFontColor;
    font-size: 1.75rem;
    text-align: left;
    animation: slide-up 0.75s ease-in-out 1.5s forwards;
    opacity: 0;
    animation-fill-mode: forwards;
  }

  .highlight {
    color: $primaryFontColor;
    font-weight: bold;
  }

  .contact-me-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    opacity: 0;
    animation: slide-up 0.95s ease-in-out 1.5s forwards;
  }
}

//laptop Styling
@media screen and (max-width: $laptopWidth) {
  .hero {
    .name {
      font-size: 3.5rem;
      animation: slide-up 0.75s ease-in-out 0s forwards;
    }
    .title {
      font-size: 2.5rem;
      animation: slide-up 0.9s ease-in-out 0s forwards;
    }
    .hero-info {
      font-size: 1.5rem;
      animation: slide-up 0.75s ease-in-out 0.5s forwards;
    }
    .contact-me-container {
      animation: slide-up 0.9s ease-in-out 0.5s forwards;
    }
  }
}

//mobile styles
@media screen and (max-width: $mobileWidth) {
  .hero {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-top: 100px;

    .name {
      font-size: 2rem;
    }
    .title {
      font-size: 1.5rem;
    }
    .hero-info {
      font-size: 1rem;
      padding-top: 30px;
    }
    .info-container {
      justify-content: space-between;
      margin: 0;
      height: 100%;
      padding-bottom: 80px;
    }
    .contact-me-container {
      justify-content: center;
      justify-self: flex-end;
      animation: slide-up 0.9s ease-in-out 0.9s forwards;
    }
  }
}
