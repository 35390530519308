@keyframes slide-in-right {
  0% {
    opacity: 0;
    transform: translateX(50vw);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-50vw);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(10vh);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-10vh);
    opactity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

//testing animations
@keyframes scale {
  0% {
    transform: scale(2, 2);
  }
  100% {
    transform: scale(1, 1);
  }
}
