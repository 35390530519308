.nav-bar {
  @include standard-padding;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 24px;
  height: 100px;
  background: $solidBlack;
}

.nav-bar-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-bar-item-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  margin-right: 20px;
}

.nav-bar-item {
  margin: 0 1px;
  padding: 0.75rem 1rem;
  opacity: 0;
  animation: slide-down 0.75s ease-in-out calc(var(--animationOrder) * 100ms)
    forwards;

  a {
    text-decoration: none;
    color: $primaryFontColor;
    border-bottom: 1px solid $primaryBrandColor;
  }
}

.resume-btn {
  opacity: 0;
  animation: slide-down 0.75s ease-in-out 0.6s forwards;
}

@media screen and (max-width: $mobileWidth) {
  .nav-bar {
    background-color: $solidBlack;
    position: fixed;
    z-index: 1;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.8);
    height: 80px;
    .nav-bar-item-list {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      height: 70%;
      padding-top: 40%;
      margin-right: 0;
    }

    .menu-icon {
      z-index: 3;
      font-size: 2rem;
      position: fixed;
      margin-right: 40px;
    }
    .nav-bar-item {
      padding: 30px 50px;
      animation: slide-in-right 0.4s ease-in-out
        calc(var(--animationOrder) * 30ms + 70ms) forwards;
    }

    .close-btn-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 0 40px;
      box-sizing: border-box;
    }

    .close-btn {
      text-align: right;
      font-size: 2rem;
    }

    .mobile-menu {
      height: 100vh;
      width: 70vw;
      background-color: rgba(0, 0, 0, 0.7);
      position: fixed;
      top: 0;
      right: 0;
      z-index: 2;
      padding-bottom: 40px;
      box-sizing: border-box;
      animation: slide-in-right 0.4s ease-in-out 0s forwards;
    }
  }

  .resume-btn {
    animation: slide-in-right 0.4s ease-in-out 0.4s forwards;
  }
}
